<template>
  <v-container fluid class="text-justify">
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">Impressum für das DV-Verfahren Geodatenbereitstellung</h2>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Dienstinhaber</h3>
        <p>
          Landesamt f&uuml;r Natur, Umwelt und Verbraucherschutz Nordrhein-Westfalen (LANUV)<br />
          Leibnizstr. 10<br />
          45659 Recklinghausen
        </p>
        <table class="mb-4">
          <tr>
            <td class="pr-2">Telefon:</td>
            <td>+49(0)2361 305-0</td>
          </tr>
          <tr>
            <td>Telefax:</td>
            <td>+49(0)2361 305-3215</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>
              <v-icon small class="mr-1">mdi-email-outline</v-icon>
              <a href="mailto:poststelle@lanuv.nrw.de">poststelle@lanuv.nrw.de</a>
            </td>
          </tr>
          <tr>
            <td>Ust.-ID:</td>
            <td>DE 126 352 455</td>
          </tr>
        </table>

        <p>
          Das LANUV ist dem Ministerium f&uuml;r Klimaschutz, Umwelt, Landwirtschaft, Natur- und Verbraucherschutz des
          Landes NRW nachgeordnet und wird vertreten durch die Pr&auml;sidentin
          <a href="https://www.lanuv.nrw.de/landesamt/lanuv-stellt-sich-vor/organisation/amtsleitung/" target="_blank"
            >Dr.&nbsp;Sibylle&nbsp;Pawlowski</a
          >.
        </p>
        <p>
          Der Diensteinhaber ist gem&auml;&szlig; &sect;&nbsp;7 Abs. 1 Telemediengesetz für die mit diesem Dienst bereit
          gestellten Inhalte verantwortlich.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Technische Realisierung und Betrieb</h3>
        <p>
          Information und Technik Nordrhein-Westfalen (IT.NRW)<br />
          - Geoinformationszentrum -<br />
          Mauerstra&szlig;e 51<br />
          40476 Düsseldorf
        </p>
        <table class="mb-4">
          <tr>
            <td class="pr-2">Telefon:</td>
            <td>+49 211 / 9449-6301</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>
              <v-icon small class="mr-1">mdi-email-outline</v-icon>
              <a href="mailto:limgeo@it.nrw.de">limgeo@it.nrw.de</a>
            </td>
          </tr>
          <tr>
            <td>Internet:</td>
            <td><a href="https://www.it.nrw.de">www.it.nrw.de</a></td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
